interface Env {
  NODE_ENV: 'development' | 'production'
  VERSION: string
  BUGSNAG_API_KEY: string
  INSTANCE_ID: string | null
  DEV_SIMULATE_INSTANCE: string
}

export const env: Env = {
  NODE_ENV: process.env.NODE_ENV === 'development' ? 'development' : 'production',
  VERSION: require(__dirname + '/../package.json')?.version ?? '',
  BUGSNAG_API_KEY: '8d270b59d0c6300dc93eac3e3db853be',
  INSTANCE_ID: getInstanceId(),
  DEV_SIMULATE_INSTANCE: '', // Don't use in gui
}

function getInstanceId(): string | null {
  // Instance id gets injected into the html by the server
  const instanceId = (window as any).instanceId
  if (typeof instanceId === 'string' && /^[0-9]+$/.test(instanceId)) {
    return instanceId
  }
  if (
    window.location.hostname === 'localhost' &&
    process.env.NODE_ENV === 'development'
  ) {
    return '0'
  }
  return null
}
