import { hermes } from '@byll/hermes'
import { Age } from 'components/Age'
import { Spinner } from 'components/Spinner'
import { toJbpId } from 'contracts/residents/helpers/toJbpId'
import { dayjs } from 'helpers/dayjs'
import { makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { Link } from 'react-router-dom'
import { AppContext } from 'services/connection/models/AppContext'
import {
  IResidentCost,
  ICostRange,
} from 'contracts/costCoverages/interfaces/IResidentCost'
import { getCostRangeColor } from 'contracts/costCoverages/helpers/getCostRangeColor'
import { Callout } from 'components/Callout'
import { Dayjs } from 'dayjs'
import { CostCoverageInvoiceDialog } from './CostCoverageInvoiceDialog'
import { box } from 'services/box'
import {
  ABRECHNUNGSMODUS_LFGB,
  ABRECHNUNGSMODUS_WOHNUNGSLOSE,
} from 'contracts/costCoverages/interfaces/abrechnungsmodus'
import { IInvoiceBuildingGroup } from 'contracts/costCoverages/interfaces/IInvoiceBuildingGroup'

interface Props {
  buildingGroupId: string | null
  compoundId: string
  month: string
  scope:
    | 'all'
    | 'missing resident signature'
    | 'missing hospital signature'
    | 'missing cost coverage'
}

@observer
export class CostCoverageListByResident extends React.Component<Props, {}> {
  static contextType = AppContext
  // This is an overwrite of the data in residents
  @observable dayOfCheckoutIsBillable = new Map<string, boolean>() // `${date}-${residentId}` => boolean billable?
  @observable.ref private residents: IResidentCost[] | null = null
  @observable.ref private buildingGroups: IInvoiceBuildingGroup[] | null = null
  @observable private error = false
  private daysInMonth: number
  private monthBegin: Dayjs

  constructor(props: Props) {
    super(props)
    this.monthBegin = dayjs(props.month, 'YYYY-MM').startOf('month')
    this.daysInMonth = this.monthBegin.daysInMonth()
    makeObservable(this)
  }

  componentDidMount() {
    void this.getResidents()
  }

  private getResidents = async () => {
    try {
      const [buildingGroups, residents] = await Promise.all([
        hermes.indexOnceNew<IInvoiceBuildingGroup>(
          `/api/${this.context.instance.id}/costCoverages/invoiceBuildingGroups?month=${this.props.month}&compoundId=${this.props.compoundId}`,
        ),
        hermes.indexOnceNew<IResidentCost>(
          `/api/${this.context.instance.id}/costCoverages/residentCosts?compoundId=${
            this.props.compoundId
          }&month=${this.props.month}&view=residents&residentScope=${encodeURIComponent(
            this.props.scope,
          )}${
            this.props.buildingGroupId
              ? `&buildingGroupId=${this.props.buildingGroupId}`
              : ''
          }`,
        ),
      ])
      runInAction(() => {
        this.buildingGroups = buildingGroups
        this.residents = residents
      })
    } catch (_e) {
      runInAction(() => (this.error = true))
    }
  }

  private toggleLastDayBillable = async (
    event,
    residentId: string,
    range: ICostRange,
  ) => {
    event.preventDefault()
    event.stopPropagation()
    const isBillable = this.dayOfCheckoutIsBillable.has(`${range.endDate}-${residentId}`)
      ? !!this.dayOfCheckoutIsBillable.get(`${range.endDate}-${residentId}`)
      : range.internal?.dayOfCheckout === 'billable'

    if (
      !window.confirm(
        `Möchten Sie wirklich festlegen, dass der Auszugstag ${
          isBillable ? 'nicht abgerechnet werden soll?' : 'abgerechnet werden soll?'
        }`,
      )
    ) {
      return
    }
    try {
      runInAction(() =>
        this.dayOfCheckoutIsBillable.set(`${range.endDate}-${residentId}`, !isBillable),
      )
      await hermes.update(
        `/api/${this.context.instance.id}/costCoverages/bookingLastDayBillable/${this.props.compoundId}`,
        {
          days: [
            {
              date: range.endDate,
              residentId,
              billable: !isBillable,
            },
          ],
        },
      )
    } catch (e) {
      runInAction(() =>
        this.dayOfCheckoutIsBillable.set(`${range.endDate}-${residentId}`, isBillable),
      )
      console.error(e)
    }
  }

  private toggleNotAllowed = () => {
    box.alert(
      'Auszugstag abrechnen',
      'Die Option zur Abrechnung des Auszugstages kann nur aktiviert werden, wenn für diesen Zeitraum eine gültige KÜ und UKN/LB vorliegt.',
      { color: 'danger' },
    )
  }

  private invoiceOnlyinKuTab = () => {
    box.alert(
      'Rechnung anzeigen',
      'Rechnungen können nur im Bereich "KÜs" angezeigt werden, da die Rechnungsstellung pro Monat und KÜ erfolgt.',
    )
  }

  private residentMapper = (r: IResidentCost) => {
    return (
      <React.Fragment key={r.id}>
        <div className='bg-white truncate ml-6 p-2 pr-4'>
          <Link
            className='underline hover:text-blue-500'
            to={`/residents/${toJbpId(+r.id).toLowerCase()}/accommodation`}
            target='_blank'
          >{`${r.lastName.toUpperCase()}, ${r.firstName}`}</Link>{' '}
          <Age dateOfBirth={r.dateOfBirth} sex={r.sex} />
        </div>
        <div className='bg-white py-2 relative'>
          {r.sections.map((b, key) => {
            const beginDay = -this.monthBegin.diff(b.beginDate, 'day')
            const endDay = -this.monthBegin.diff(b.endDate, 'day')
            const color = getCostRangeColor(b)
            const offerInvoice =
              color.endsWith('bg-green-500') &&
              this.context.permissions.resident_abrechnung_invoice &&
              this.context.permissions.abrechnungsmodus !==
                ABRECHNUNGSMODUS_WOHNUNGSLOSE &&
              (this.buildingGroups?.length === 0 || this.props.buildingGroupId !== null)
            const lastDayIsBillable = this.dayOfCheckoutIsBillable.has(
              `${b.endDate}-${r.id}`,
            )
              ? !!this.dayOfCheckoutIsBillable.get(`${b.endDate}-${r.id}`)
              : b.internal?.dayOfCheckout === 'billable'
            return (
              <div
                key={key}
                className='absolute truncate text-white'
                style={{
                  padding: '0 1px',
                  left: `${(beginDay / this.daysInMonth) * 100}%`,
                  width: `${((endDay - beginDay + 1) / this.daysInMonth) * 100}%`,
                  height: 21,
                }}
              >
                {!offerInvoice && (
                  <div
                    className={`${color} ${
                      offerInvoice ? 'cursor-pointer' : ''
                    } rounded-sm px-1`}
                  >
                    {b.external?.reason === 'Krankenhausaufenthalt' && (
                      <span className='mr-1'>
                        <i className='fas fa-ambulance' />
                      </span>
                    )}
                    {(b.external?.reason === 'Genehmigte Ortsabwesenheit' ||
                      b.external?.reason === '') && (
                      <span className='mr-1'>
                        <i className='fas fa-umbrella-beach' />
                      </span>
                    )}
                    {b.internal ? (
                      `${
                        this.context.instance.id === '0' && b.internal.hasCheckIn
                          ? '* '
                          : ''
                      }${getDateLabel(
                        dayjs(b.beginDate),
                        dayjs(b.endDate),
                        b.internal.hasCheckOut,
                      )}`
                    ) : (
                      <>&nbsp;</>
                    )}
                    {b.internal?.hasCheckOut && (
                      <div
                        className='absolute top-1 right-1 h-3 w-3 bg-white rounded-sm cursor-pointer'
                        onClick={this.toggleNotAllowed}
                      />
                    )}
                  </div>
                )}
                {offerInvoice && (
                  <div onClick={this.invoiceOnlyinKuTab} className='cursor-pointer'>
                    {' '}
                    {/* to={this.context.permissions.abrechnungsmodus === ABRECHNUNGSMODUS_LFGB ? `${b.coverage?.id || ''}-${this.props.buildingGroupId || '0'}-${this.props.month}` : ''} */}
                    <div className={`${color} rounded-sm px-1`}>
                      {b.external?.reason === 'Krankenhausaufenthalt' && (
                        <span className='mr-1'>
                          <i className='fas fa-ambulance' />
                        </span>
                      )}
                      {(b.external?.reason === 'Genehmigte Ortsabwesenheit' ||
                        b.external?.reason === '') && (
                        <span className='mr-1'>
                          <i className='fas fa-umbrella-beach' />
                        </span>
                      )}
                      {b.internal ? (
                        `${
                          this.context.instance.id === '0' && b.internal.hasCheckIn
                            ? '* '
                            : ''
                        }${getDateLabel(
                          dayjs(b.beginDate),
                          dayjs(b.endDate),
                          b.internal.hasCheckOut,
                        )}`
                      ) : (
                        <>&nbsp;</>
                      )}
                      {b.internal?.hasCheckOut && (
                        <div
                          className='absolute top-1 right-1 h-3 w-3 bg-white rounded-sm text-green-500 text-center'
                          style={{ lineHeight: '13px' }}
                          onClick={(event) => this.toggleLastDayBillable(event, r.id, b)}
                        >
                          {lastDayIsBillable ? '$' : ''}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            )
          })}
        </div>
        <div className='bg-white mr-6' style={{ width: 8 }} />
      </React.Fragment>
    )
  }

  render() {
    if (!this.residents) {
      return (
        <div className='flex-auto bg-gray-100 flex'>
          <Spinner delay />
        </div>
      )
    }
    if (this.error) {
      return (
        <div className='flex-auto bg-gray-100 flex'>
          <Callout
            icon='fas fa-exclamation-triangle'
            iconColor='#ef4444'
            title='Die Liste konnte nicht geladen werden.'
          />
        </div>
      )
    }
    if (this.residents.length === 0) {
      return (
        <div className='flex-auto bg-gray-100 flex'>
          <Callout
            icon='fas fa-list-ul'
            iconColor='#8b5cf6'
            title='Für den gewählten Filter liegen keine Belegungen vor.'
          />
        </div>
      )
    }

    return (
      <>
        <div className='relative md:sticky md:z-1 md:top-[197px] xl:top-[143px] flex-content flex px-6 py-3 bg-gray-100 gap-3 text-sm flex-wrap'>
          <div className='whitespace-nowrap bg-yellow-500 rounded-md text-white py-1 px-2'>
            Belegung ohne KÜ
          </div>
          {this.context.permissions.abrechnungsmodus === ABRECHNUNGSMODUS_LFGB && (
            <div className='whitespace-nowrap bg-indigo-500 rounded-md text-white py-1 px-2'>
              <i className='fas fa-ambulance' /> Krankenhaus
            </div>
          )}
          {this.context.permissions.abrechnungsmodus === ABRECHNUNGSMODUS_LFGB && (
            <div className='whitespace-nowrap bg-indigo-500 rounded-md text-white py-1 px-2'>
              <i className='fas fa-umbrella-beach' /> Abwesenheit
            </div>
          )}
          <div className='whitespace-nowrap bg-green-500 rounded-md text-white py-1 px-2'>
            Abrechenbar
          </div>
          {this.context.permissions.abrechnungsmodus === ABRECHNUNGSMODUS_LFGB && (
            <div className='whitespace-nowrap bg-red-500 rounded-md text-white py-1 px-2'>
              KÜ liegt vor, aber UKN/LB fehlt
            </div>
          )}
          {this.context.permissions.abrechnungsmodus === ABRECHNUNGSMODUS_LFGB && (
            <div className='whitespace-nowrap bg-gray-500 rounded-md text-white py-1 px-2'>
              KÜ ungültig
            </div>
          )}
          <div className='whitespace-nowrap bg-white rounded-md text-gray-500 py-1 px-2'>
            <i className='fas fa-stop' /> Belegungsende
          </div>
        </div>
        <div className='flex-content bg-gray-100 grid grid-cols-[250px_1fr_max-content] gap-y-4 pb-6 pt-1 text-sm'>
          {this.residents.map(this.residentMapper)}
          <CostCoverageInvoiceDialog />
        </div>
      </>
    )
  }
}

function getDateLabel(beginDate: Dayjs, endDate: Dayjs, hasCheckOut?: boolean): string {
  if (beginDate.isSame(endDate, 'day')) {
    return hasCheckOut ? endDate.format('DD.') : endDate.format('DD.MM.')
  }
  return `${beginDate.format('DD.')} - ${endDate.format('DD.MM.')}`
}
