import { dispose, Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { Model } from 'components/Form/Model'
import { IResidentSearchResult } from 'contracts/residents/interfaces/IResidentSearchResult'
import { IResidentSearchResultsFilter } from 'contracts/residents/interfaces/IResidentSearchResultsFilter'
import { IResidentSearchResultsMetadata } from 'contracts/residents/interfaces/IResidentSearchResultsMetadata'
import { toLocaleNumber } from 'contracts/general/helpers/toLocaleNumber'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { FindRecordResults } from './components/FindRecordResults'
import { FindRecordSearchBar } from './components/FindRecordSearchBar'
import { userNavigatedBackToSearchFromCaseRecord } from './helpers/userNavigatedBackToSearchFromCaseRecord'
import { isEmptySearchResultsFilter } from 'contracts/residents/helpers/isEmptySearchResultsFilter'
import { LoadMoreButton } from 'components/LoadMoreButton'
import { action, makeObservable, observable, reaction, runInAction, toJS } from 'mobx'
import { SelectResponsibility } from './components/SelectResponsibility'
import { SelectNationality } from './components/SelectNationality'
import { SelectTodo } from './components/SelectTodo'
import { SelectDate } from './components/SelectDate'
import { SelectAgeBracket } from './components/SelectAgeBracket'
import { SelectSex } from './components/SelectSex'
import { UnsyncedCollection } from './models/UnsyncedCollection'
import { SelectCostCoverage } from './components/SelectCostCoverage'
import { InputSelectOption } from 'components/Form/components/InputSelect'
import { getDefaultResidentTodos } from 'contracts/todos/helpers/getDefaultResidentTodos'
import { SelectSchutzbedarf } from './components/SelectSchutzbedarf'
import { ResidentSearchResultsFilterValidator } from 'contracts/residents/validators/ResidentSearchResultsFilterValidator'
import { storage } from 'services/storage'
import { getEmptySearchFilter } from './helpers/getEmptySearchFilter'
import { z } from 'zod'
import { SelectAccommodation } from './components/SelectAccommodation'
import { isAwumInstance, isLfgInstance } from 'contracts/general/helpers/instanceIds'
import { SelectAwumBillingStatus } from './components/SelectAwumBillingStatus'
import { SelectEndOfStay } from './components/SelectEndOfStay'
let lastSearch: any

const StorageValidator = z.object({
  model: ResidentSearchResultsFilterValidator,
  permission: z.number().refine((n) => Number.isInteger(n) && n >= 0 && n <= 3),
})

interface Props {}

@observer
export class FindRecord extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly residents: UnsyncedCollection<
    IResidentSearchResult,
    IResidentSearchResultsMetadata,
    IResidentSearchResultsFilter
  >
  private model: Model<IResidentSearchResultsFilter>
  private readonly disposers: Disposer[] = []
  private readonly resultsPerPage: number
  private readonly defaultTodoOptions: InputSelectOption[] | null = null
  @observable togglePinModel = { editModel: false }

  constructor(props: Props, context: AppContextProps) {
    super(props)
    makeObservable(this)
    const width = window.innerWidth
    if (width >= 1800) {
      this.resultsPerPage = 32 // 8 cols
    } else if (width >= 1400) {
      this.resultsPerPage = 28 // 7 cols
    } else if (width >= 1200) {
      this.resultsPerPage = 30 // 6 cols
    } else if (width >= 992) {
      this.resultsPerPage = 32 // 4 cols
    } else if (width >= 640) {
      this.resultsPerPage = 30 // 3 cols
    } else {
      this.resultsPerPage = 30 // 1col
    }

    const defaultTodos = getDefaultResidentTodos(context.instance.id)
    if (defaultTodos.length > 0) {
      this.defaultTodoOptions = defaultTodos.map((t) => ({
        value: t.label,
        label: t.label,
      }))
      this.defaultTodoOptions.unshift({ value: 'Alle Aufgaben', label: 'Alle Aufgaben' })
      this.defaultTodoOptions.unshift({
        value: 'Pfortenbenachrichtigung',
        label: 'Pfortenbenachrichtigung',
      })
      this.defaultTodoOptions.unshift({ value: null, label: 'Bitte wählen...' })
    }

    if (context.permissions.menu_resident_search === 1) {
      runInAction(() => {
        context.defaults.residentSearch.view = 'list'
        context.defaults.residentSearch.fields =
          'dateOfBirthDe,age,accommodation,nationality,raum'
      })
    }
    const responsibleCompoundId =
      context.permissions.menu_resident_search < 3
        ? context.defaults.stammCompoundIds.length === 1
          ? context.defaults.stammCompoundIds[0]
          : '0' // '0' => Interpreted by backend as "Nur Stammgelände"
        : ''

    const storageModel = this.getStorageModel(context)

    this.model = storageModel
      ? new Model(storageModel)
      : new Model<IResidentSearchResultsFilter>(
          getEmptySearchFilter(context, responsibleCompoundId, this.resultsPerPage),
        )

    if (isAwumInstance(context.instance.id)) {
      this.model.values.responsibleCompoundId = ''
      this.model.values.responsibleScope = null
      this.model.values.visit = null
      this.model.values.deleted = 'no'
    }

    if (this.model.values.allowMultipleCompoundIds === 'yes') {
      this.model.values.compoundId = this.model.values.compoundId || ''
    } else {
      this.model.values.compoundId =
        typeof this.model.values.compoundId === 'string'
          ? this.model.values.compoundId.split(',')[0] || null
          : null
    }
    this.model.values.buildingId = this.model.values.compoundId
      ? this.model.values.buildingId || null
      : null
    this.model.values.floorId = this.model.values.buildingId
      ? this.model.values.floorId || ''
      : ''
    this.model.values.checkedIn = this.model.values.checkedIn || 'anytime'
    this.model.values.checkedInBegin = this.model.values.checkedInBegin || null
    this.model.values.checkedInEnd = this.model.values.checkedInEnd || null

    this.residents = new UnsyncedCollection(
      `/api/${context.instance.id}/residentSearchResults`,
      this.model.values,
    )
  }

  componentDidMount() {
    if (lastSearch && userNavigatedBackToSearchFromCaseRecord()) {
      runInAction(() => {
        for (const key of Object.keys(this.model.values)) {
          this.model.values[key] = lastSearch[key]
        }
        if (!this.context.permissions.resident_showPresence) {
          this.model.values.visit = null
        }
      })
    }

    this.disposers.push(this.residents.init())
    this.disposers.push(
      reaction(
        () => this.model.values.searchString,
        () => (this.residents.query.page = `0,${this.resultsPerPage}`),
      ),
    )
    this.disposers.push(
      reaction(
        () => toJS(this.model),
        () => {
          if (this.togglePinModel.editModel) {
            this.pinModel()
          }
        },
      ),
    )
    this.disposers.push(
      reaction(
        () => this.togglePinModel.editModel,
        (hasPin) => {
          if (hasPin) {
            this.pinModel()
          } else {
            storage.remove(`pinnedSearchModel.${this.context.user.id}`)
          }
        },
      ),
    )
  }

  componentWillUnmount() {
    dispose(this.disposers)
    lastSearch = toJS(this.model.values)
  }

  @action
  private getStorageModel = (context: AppContextProps) => {
    const result = StorageValidator.safeParse(
      storage.get(`pinnedSearchModel.${context.user.id}`),
    )
    // validate model and permission
    if (
      !result.success ||
      result.data.permission !== context.permissions.menu_resident_search
    ) {
      storage.remove(`pinnedSearchModel.${context.user.id}`)
      return null
    }
    this.togglePinModel.editModel = true

    if (isAwumInstance(context.instance.id)) {
      result.data.model.responsibleCompoundId = ''
      result.data.model.responsibleScope = null
      result.data.model.visit = null
      result.data.model.deleted = 'no'
    }

    return result.data.model
  }

  private pinModel = () => {
    storage.set(`pinnedSearchModel.${this.context.user.id}`, {
      model: this.model.values,
      permission: this.context.permissions.menu_resident_search,
    })
  }

  render() {
    return (
      <>
        <div className='pt-14 fixed flex flex-col w-full z-20 max-w-full-hd'>
          <FindRecordSearchBar
            model={this.model}
            config={this.context.defaults}
            residents={this.residents}
            togglePinModel={this.togglePinModel}
          />
        </div>
        <div className='px-4 md:px-6 py-3 flex text-sm text-gray-500 flex-wrap z-10 pt-[139px] md:pt-[155px]'>
          {!isAwumInstance(this.context.instance.id) && (
            <SelectResponsibility
              model={this.model}
              readOnly={this.context.permissions.menu_resident_search === 1}
            />
          )}
          {!isLfgInstance(this.context.instance.id) && (
            <SelectAccommodation model={this.model} />
          )}
          {isAwumInstance(this.context.instance.id) && (
            <SelectEndOfStay model={this.model} />
          )}
          <SelectSex model={this.model} />
          {this.context.permissions.menu_resident_search !== 1 && (
            <SelectNationality model={this.model} />
          )}
          {this.context.permissions.menu_resident_search !== 1 &&
            this.defaultTodoOptions && (
              <SelectTodo
                model={this.model}
                defaultTodoOptions={this.defaultTodoOptions}
              />
            )}
          <SelectAgeBracket model={this.model} />
          <SelectDate model={this.model} />
          {this.context.permissions.menu_resident_search !== 1 &&
            this.context.permissions.host_lfgb && (
              <SelectSchutzbedarf model={this.model} />
            )}
          {isLfgInstance(this.context.instance.id) &&
            this.context.permissions.menu_resident_search !== 1 && (
              <SelectCostCoverage model={this.model} />
            )}
          {isAwumInstance(this.context.instance.id) && (
            <SelectAwumBillingStatus model={this.model} />
          )}

          {/*<span className="has-tooltip cursor-pointer inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-indigo-500 text-white">
            <i className='fas fa-plus mr-1' /> Merken
            <Tooltip>Speichern Sie die aktuellen<br />Suchfilter als Kurzwahl, um sie<br />schnell wiederherzustellen.</Tooltip>
          </span>

          <span className="cursor-pointer ml-2 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-indigo-500 text-white">
            Mein Filter
            <span className='has-tooltip ml-1'>✕<Tooltip>Kurzwahl löschen</Tooltip></span>
          </span>

          <span className='has-tooltip cursor-pointer ml-2 text-gray-400'>
            <i className='fas fa-cog' />
            <Tooltip>Startfilter festlegen</Tooltip>
          </span>*/}

          <div className='flex-auto md:text-right'>
            {this.residents.metadata &&
            !isEmptySearchResultsFilter(this.residents.query) &&
            this.residents.resources &&
            this.residents.resources.length > 0 ? (
              <>
                <span className='text-gray-500 text-sm'>{`${toLocaleNumber(
                  String(this.residents.metadata.count),
                )} ${
                  this.residents.metadata.count === 1 ? 'Ergebnis' : 'Ergebnisse'
                }`}</span>{' '}
                <span className='text-blue-500 text-sm ml-3'>
                  <i className='far fa-hourglass' aria-hidden='true' />{' '}
                  {this.residents.metadata.duration} ms
                </span>
              </>
            ) : (
              <span>&nbsp;</span>
            )}
          </div>
        </div>

        <FindRecordResults
          key={this.context.defaults.residentSearch.fields}
          residents={this.residents}
          config={this.context.defaults}
        />
        <div className='p-4 text-center mb-3'>
          {this.residents.resources && this.residents.resources.length > 6 && (
            <LoadMoreButton
              collection={this.residents}
              incrementBy={this.resultsPerPage * 2}
            />
          )}
        </div>
      </>
    )
  }
}
