import { InputCompound } from 'components/Form/components/InputCompound'
import * as React from 'react'
import { Model } from '../../../components/Form/Model'
import { observer } from 'mobx-react'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { Button } from 'components/Form/components/Button'
import { InputMonth } from 'components/Form/components/InputMonth'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { IResidentCostsFilterValidator } from 'contracts/costCoverages/interfaces/IResidentCostsFilterValidator'
import { createAndDownloadReport } from 'helpers/createAndDownloadReport'
import { box } from 'services/box'
import { CostCoverageDownloadDropdown } from './CostCoverageDownloadDropdown'
import { CostCoverageResidentScopeDropdown } from './CostCoverageResidentScopeDropdown'
import { CostCoverageScopeDropdown } from './CostCoverageScopeDropdown'
import { InputSelect, InputSelectOption } from 'components/Form/components/InputSelect'
import { ICostCoverageMeta } from 'contracts/costCoverages/interfaces/ICostCoverageMeta'
import { CreateCostCoverageInvoicesDialog } from './CreateCostCoverageInvoicesDialog'
import { CreateBatchUknDialog } from './CreateBatchUknDialog'
import { CreateBatchCostCoverageDialog } from './CreateBatchCostCoverageDialog'
import { isStammCompound } from 'helpers/isStamm'
import { UploadBatchUknDialog } from './UploadBatchUknDialog'
import { CostPeriodStatus } from './CostPeriodStatus'
import { CostCoverageInvoiceBatchesDialog } from './CostCoverageInvoiceBatchesDialog'
import { dayjs } from 'helpers/dayjs'
import { CostCoverageBuildingGroupDropdown } from './CostCoverageBuildingGroupDropdown'
import {
  ABRECHNUNGSMODUS_LFGB,
  ABRECHNUNGSMODUS_WOHNUNGSLOSE,
} from 'contracts/costCoverages/interfaces/abrechnungsmodus'
import { CreateEinzelrechnungenWohnungsloseDialog } from './CreateEinzelrechnungenWohnungsloseDialog'
interface Props {
  model: Model<
    IResidentCostsFilterValidator & {
      clear: number
      payerId: string | null
      payers: InputSelectOption[]
      selected: Map<string, ICostCoverageMeta | null>
      coverageIds: string[]
    }
  >
}

interface IReport {
  report: string
  label: string
}

@observer
export class CostCoverageFilter extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable private downloading = false
  private readonly reports: IReport[] = []
  private readonly fromMonth = dayjs().subtract(36, 'month').format('YYYY-MM')

  constructor(props: Props, context: AppContextProps) {
    super(props)
    this.reports.push({ report: 'current-cost-view', label: 'Aktuelle Ansicht' })
    if (context.permissions.resident_ukns > 0) {
      this.reports.push({ report: 'create-ukns', label: 'UKNs erstellen' })
      this.reports.push({ report: 'upload-ukns', label: 'UKNs hochladen' })
    }
    if (context.permissions.resident_abrechnung_sammeldokument_ku) {
      this.reports.push({
        report: 'concatinate-cost-coverages',
        label: 'KÜ Sammeldokument',
      })
    }
    if (
      context.permissions.resident_abrechnung_invoice &&
      context.permissions.abrechnungsmodus === ABRECHNUNGSMODUS_LFGB
    ) {
      this.reports.push({ report: 'create-invoices', label: 'Rechnungen erstellen' })
    }
    if (
      context.permissions.resident_abrechnung_invoice_stornieren &&
      context.permissions.abrechnungsmodus === ABRECHNUNGSMODUS_LFGB
    ) {
      this.reports.push({ report: 'cancel-invoices', label: 'Rechnungen stornieren' })
    }
    if (
      context.permissions.resident_abrechnung_invoice_stapel &&
      context.permissions.abrechnungsmodus === ABRECHNUNGSMODUS_LFGB
    ) {
      this.reports.push({ report: 'invoice-batches', label: 'Rechnungsstapel' })
    }
    if (
      context.permissions.resident_abrechnung_invoice_attachments &&
      context.permissions.abrechnungsmodus === ABRECHNUNGSMODUS_LFGB
    ) {
      this.reports.push({ report: 'concatinate-inv-cc-ukn', label: 'Rechnungsanhänge' })
    }
    if (context.permissions.resident_abrechnung_pruefliste) {
      this.reports.push({ report: 'cost-coverage-pruefliste', label: 'Prüfliste RW' })
    }
    if (context.permissions.resident_abrechnung_pruefliste) {
      this.reports.push({ report: 'cost-coverage-pruefliste-uk', label: 'Prüfliste UK' })
    }
    if (context.permissions.resident_abrechnung_download_gesamtuebersicht) {
      this.reports.push({
        report: 'cost-coverage-gesamtuebersicht',
        label: 'Gesamtübersicht',
      })
    }
    if (context.permissions.resident_abrechnung_download_fehltageuebersicht) {
      this.reports.push({
        report: 'cost-coverage-fehltageuebersicht',
        label: 'Fehltageübersicht',
      })
    }
    if (context.permissions.resident_abrechnung_download_laf) {
      this.reports.push({
        report: 'cost-coverage-abrechnung-laf',
        label: 'Abrechnung LAF',
      })
    }
    if (context.permissions.resident_abrechnung_download_nachberechnung) {
      this.reports.push({
        report: 'cost-coverage-nachberechnung',
        label: 'Nachberechnung',
      })
    }
    if (
      context.permissions.resident_abrechnung_invoice &&
      context.permissions.abrechnungsmodus === ABRECHNUNGSMODUS_WOHNUNGSLOSE
    ) {
      this.reports.push({
        report: 'cost-coverage-liste-wohnungslose',
        label: 'Übersichtsrechnung',
      })
      this.reports.push({
        report: 'einzelrechnungen-wohnungslose',
        label: 'Einzelrechnungen',
      })
    }
    makeObservable(this)
  }

  private download = async (report: IReport) => {
    if (this.downloading) {
      return
    }
    if (!this.props.model.values.compoundId) {
      await box.alert('Gelände benötigt', 'Bitte wählen Sie zunächst ein Gelände aus.')
      return
    }

    const coverages: ICostCoverageMeta[] = []
    if (this.props.model.values.view === 'cost coverages') {
      for (const coverageId of this.props.model.values.coverageIds) {
        const coverage = this.props.model.values.selected.get(coverageId)
        if (!coverage) {
          continue
        }
        if (
          this.props.model.values.payerId &&
          coverage.payer.id !== this.props.model.values.payerId
        ) {
          continue
        }
        coverages.push(coverage)
      }
      /*coverages.sort((a, b) => {
        return `${a.residents[0]?.lastName || '_____________'}, ${a.residents[0]?.firstName}`.toLowerCase() < `${b.residents[0]?.lastName || '_____________'}, ${b.residents[0]?.firstName}`.toLowerCase() ? -1 : 1
      })*/
    }

    if (report.report === 'create-invoices') {
      if (this.props.model.values.view !== 'cost coverages') {
        void box.alert(
          'KÜ Ansicht',
          'Bitte wechseln Sie für diese Funktion in die Ansicht "KÜs" und wählen Sie diejenigen KÜs aus, für die eine Rechnung erstellt werden soll.',
        )
        return
      }
      for (const coverage of coverages) {
        if (coverage.status === '') {
          void box.alert(
            'Einzelgebäudeabrechnung',
            'Die ausgewählten KÜs können nur für einzelne Gebäude abgerechnet werden. Bitte wählen Sie im Filter oben ein Gebäude für die Abrechnung aus.',
          )
          return
        }
        if (coverage.status !== 'not billed') {
          void box.alert(
            'Bereits abgerechnet',
            'Es wurde mindestens eine der ausgewählten KÜs bereits abgerechnet. Bitte wählen Sie ausschließlich Entwürfe für die Abrechnung aus.',
          )
          return
        }
      }
      if (coverages.length === 0) {
        void box.alert(
          'Keine KÜs ausgewählt',
          'Wählen Sie mindestens eine KÜ aus, um mit der Rechnungsstellung zu beginnen.',
        )
        return
      }
      const promise = box.custom(
        <CreateCostCoverageInvoicesDialog
          costCoverageIds={coverages.map((c) => c.id)}
          month={this.props.model.values.month}
          compoundId={this.props.model.values.compoundId}
          buildingGroupId={this.props.model.values.buildingGroupId || null}
          onClose={() => promise.close()}
          model={this.props.model}
        />,
        { context: this.context },
      )
      return
    }

    if (report.report === 'cancel-invoices') {
      if (this.props.model.values.view !== 'cost coverages') {
        void box.alert(
          'KÜ Ansicht',
          'Bitte wechseln Sie für diese Funktion in die Ansicht "KÜs" und wählen Sie diejenigen KÜs aus, deren Rechnungen storniert werden sollen.',
        )
        return
      }
      for (const coverage of coverages) {
        if (coverage.status === '') {
          void box.alert(
            'Einzelgebäudestorno',
            'Die ausgewählten KÜs können nur für einzelne Gebäude storniert werden. Bitte wählen Sie im Filter oben ein Gebäude für die Stornierung aus.',
          )
          return
        }
        if (coverage.status !== 'billed') {
          void box.alert(
            'Nicht stornierbar',
            'Mindestens eine der ausgewählten KÜs ist noch nicht abgerechnet. Bitte wählen Sie ausschließlich abgerechnete KÜs für die Stornierung aus.',
          )
          return
        }
      }
      if (coverages.length === 0) {
        void box.alert(
          'Keine KÜs ausgewählt',
          'Wählen Sie mindestens eine KÜ aus, um mit der Stornierung zu beginnen.',
        )
        return
      }
      const promise = box.custom(
        <CreateCostCoverageInvoicesDialog
          storno
          costCoverageIds={coverages.map((c) => c.id)}
          month={this.props.model.values.month}
          compoundId={this.props.model.values.compoundId}
          buildingGroupId={this.props.model.values.buildingGroupId || null}
          onClose={() => promise.close()}
          model={this.props.model}
        />,
        { context: this.context },
      )
      return
    }

    if (report.report === 'invoice-batches') {
      const promise = box.custom(
        <CostCoverageInvoiceBatchesDialog
          month={this.props.model.values.month}
          compoundId={this.props.model.values.compoundId}
          onClose={() => promise.close()}
        />,
        { context: this.context },
      )
      return
    }

    if (report.report === 'create-ukns') {
      if (this.props.model.values.view !== 'cost coverages') {
        void box.alert(
          'KÜ Ansicht',
          'Bitte wechseln Sie für diese Funktion in die Ansicht "KÜs" und wählen Sie diejenigen KÜs aus, für die ein UKN erstellt werden soll.',
        )
        return
      }
      if (coverages.length === 0) {
        void box.alert(
          'Keine KÜs ausgewählt',
          'Wählen Sie mindestens eine KÜ aus, um UKNs zu erstellen.',
        )
        return
      }
      const promise = box.custom(
        <CreateBatchUknDialog
          costCoverageIds={coverages.map((c) => c.id)}
          month={this.props.model.values.month}
          onClose={() => promise.close()}
          model={this.props.model}
        />,
        { context: this.context },
      )
      return
    }

    if (report.report === 'upload-ukns') {
      if (
        this.context.permissions.resident_ukns === 1 &&
        !isStammCompound(this.props.model.values.compoundId)
      ) {
        void box.alert(
          'UKN Upload',
          'Sie haben keine Berechtigung, UKNs für die gewählte Unterkunft hochzuladen, da es sich nicht um ein Stammgelände handelt.',
        )
        return
      }
      const promise = box.custom(
        <UploadBatchUknDialog onClose={() => promise.close()} />,
        { context: this.context },
      )
      return
    }

    if (report.report === 'concatinate-cost-coverages') {
      if (this.props.model.values.view !== 'cost coverages') {
        void box.alert(
          'KÜ Ansicht',
          'Bitte wechseln Sie für diese Funktion in die Ansicht "KÜs" und wählen Sie diejenigen KÜs aus, die Sie als Sammeldokument herunterladen möchten.',
        )
        return
      }
      if (coverages.length === 0) {
        void box.alert(
          'Keine KÜs ausgewählt',
          'Wählen Sie mindestens eine KÜ aus, um ein Sammeldokument zu erstellen.',
        )
        return
      }
      const promise = box.custom(
        <CreateBatchCostCoverageDialog
          coverages={coverages}
          onClose={() => promise.close()}
          type='concatenateCoverages'
          compoundId={this.props.model.values.compoundId}
          buildingGroupId={this.props.model.values.buildingGroupId || null}
          month={this.props.model.values.month}
        />,
        { context: this.context },
      )
      return
    }

    if (report.report === 'concatinate-inv-cc-ukn') {
      if (this.props.model.values.view !== 'cost coverages') {
        void box.alert(
          'KÜ Ansicht',
          'Bitte wechseln Sie für diese Funktion in die Ansicht "KÜs" und wählen Sie diejenigen KÜs aus, die Sie als Sammeldokument herunterladen möchten.',
        )
        return
      }
      if (coverages.length === 0) {
        void box.alert(
          'Keine KÜs ausgewählt',
          'Wählen Sie mindestens eine KÜ aus, um ein Sammeldokument zu erstellen.',
        )
        return
      }
      for (const coverage of coverages) {
        if (coverage.status === '') {
          void box.alert(
            'Einzelgebäudeabrechnung',
            'Dieser Download kann nur für einzelne Gebäude erstellt werden. Bitte wählen Sie im Filter oben ein Gebäude aus.',
          )
          return
        }
      }
      const promise = box.custom(
        <CreateBatchCostCoverageDialog
          coverages={coverages}
          onClose={() => promise.close()}
          type='concatenateAll'
          compoundId={this.props.model.values.compoundId}
          buildingGroupId={this.props.model.values.buildingGroupId || null}
          month={this.props.model.values.month}
        />,
        { context: this.context },
      )
      return
    }

    if (report.report === 'einzelrechnungen-wohnungslose') {
      if (!this.props.model.values.buildingGroupId) {
        void box.alert(
          'Kein Gebäude ausgewählt',
          'Wählen Sie ein Gebäude aus, um die Einzelrechnungen zu erstellen.',
        )
        return
      }
      const promise = box.custom(
        <CreateEinzelrechnungenWohnungsloseDialog
          onClose={() => promise.close()}
          compoundId={this.props.model.values.compoundId}
          buildingGroupId={this.props.model.values.buildingGroupId}
          month={this.props.model.values.month}
        />,
        { context: this.context },
      )
      return
    }

    let data: any = {
      compoundId: this.props.model.values.compoundId,
      month: this.props.model.values.month,
    }
    if (report.report === 'cost-coverage-liste-wohnungslose') {
      if (!this.props.model.values.buildingGroupId) {
        void box.alert(
          'Kein Gebäude ausgewählt',
          'Wählen Sie ein Gebäude aus, um die Übersichtsrechnung zu erstellen.',
        )
        return
      }
      data.buildingGroupId = this.props.model.values.buildingGroupId
    }
    if (report.report === 'current-cost-view') {
      // This report is also implemented for this.props.model.values.view === 'residents'
      // It is only excluded in the ui because lfg-b didn't want to include it.
      if (this.props.model.values.view !== 'families') {
        void box.alert(
          'Familienansicht',
          'Bitte wechseln Sie für diesen Download in die Familienansicht.',
        )
        return
      }
      data = this.props.model.values
    }
    runInAction(() => (this.downloading = true))
    try {
      await createAndDownloadReport(
        report.report,
        this.context.instance.id,
        data,
        `${report.label}.xlsx`,
      )
    } catch (_e) {
      /* */
    }
    runInAction(() => (this.downloading = false))
  }

  @action private setViewResidents = () => (this.props.model.values.view = 'residents')
  @action private setViewFamilies = () => (this.props.model.values.view = 'families')
  @action private setViewCoverages = () =>
    (this.props.model.values.view = 'cost coverages')
  @action private reload = () => this.props.model.values.clear++

  render() {
    const view = this.props.model.values.view
    return (
      <div className='relative md:sticky flex-content grid grid-cols-1 md:grid-cols-[max-content_max-content_max-content_1fr] xl:grid-cols-[max-content_316px_max-content_max-content_1fr_max-content] gap-4 p-6 bg-white shadow border-b border-gray-300 md:top-[56px] z-2'>
        <div className='col-span-1 text-lg'>
          <CostPeriodStatus
            key={`${this.props.model.values.compoundId}-${this.props.model.values.month}`}
            id={`${this.props.model.values.compoundId}-${this.props.model.values.month}`}
            model={this.props.model}
          />
        </div>
        <div className='md:col-span-3 xl:col-span-1 flex gap-4'>
          <InputCompound
            className='flex-[1_1_150px]'
            model={this.props.model}
            name='compoundId'
            label='Gelände'
            onlyStamm={this.context.permissions.menu_resident_abrechnung === 1}
            saveResponsibility
            at={dayjs(this.props.model.values.month, 'YYYY-MM').toISOString()}
          />
          {this.props.model.values.compoundId && (
            <CostCoverageBuildingGroupDropdown
              key={`${this.props.model.values.compoundId}/${this.props.model.values.month}`}
              compoundId={this.props.model.values.compoundId}
              month={this.props.model.values.month}
              model={this.props.model}
            />
          )}
        </div>
        <InputMonth model={this.props.model} name='month' fromMonth={this.fromMonth} />
        <div className='flex whitespace-nowrap'>
          <Button
            onClick={this.setViewResidents}
            color={view === 'residents' ? 'primary' : 'secondary'}
            outline={view !== 'residents'}
            style={{ borderRadius: '6px 0 0 6px' }}
          >
            Bewohner
          </Button>
          <Button
            onClick={this.setViewFamilies}
            color={view === 'families' ? 'primary' : 'secondary'}
            outline={view !== 'families'}
            style={{ borderRadius: 0, borderLeft: 'none', borderRight: 'none' }}
          >
            Familien
          </Button>
          <Button
            onClick={this.setViewCoverages}
            color={view === 'cost coverages' ? 'primary' : 'secondary'}
            outline={view !== 'cost coverages'}
            style={{ borderRadius: '0 6px 6px 0' }}
          >
            KÜs
          </Button>
        </div>

        {(this.props.model.values.view === 'residents' ||
          this.props.model.values.view === 'families') && (
          <CostCoverageResidentScopeDropdown model={this.props.model} />
        )}

        {this.props.model.values.view === 'cost coverages' && (
          <div className='flex gap-4'>
            <CostCoverageScopeDropdown model={this.props.model} />
            {this.props.model.values.payers.length > 0 && (
              <InputSelect
                className='flex-[0_0_150px] xl:flex-[0_1_150px]'
                label='Kostenträger'
                model={this.props.model}
                name='payerId'
                options={this.props.model.values.payers}
              />
            )}
          </div>
        )}

        <div className='flex whitespace-nowrap md:ml-auto'>
          <Button
            onClick={this.reload}
            color='primary'
            className='border-r border-transparent hover:border-indigo-700'
            style={{
              width: 38,
              height: 38,
              padding: 0,
              borderRadius: this.reports.length > 0 ? '6px 0 0 6px' : undefined,
            }}
          >
            <i className='fas fa-sync' />
          </Button>

          {this.reports.length > 0 && this.downloading && (
            <Button
              color='primary'
              style={{ width: 38, height: 38, padding: 0, borderRadius: '0 6px 6px 0' }}
            >
              <i className='fas fa-sync fa-spin' />
            </Button>
          )}
          {this.reports.length > 0 && !this.downloading && (
            <CostCoverageDownloadDropdown
              reports={this.reports}
              onSelect={this.download}
              width={180}
            />
          )}
        </div>
      </div>
    )
  }
}
