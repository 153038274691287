import { hermes } from '@byll/hermes'
import { Age } from 'components/Age'
import { Spinner } from 'components/Spinner'
import { toJbpId } from 'contracts/residents/helpers/toJbpId'
import { dayjs } from 'helpers/dayjs'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import * as React from 'react'
import { Link } from 'react-router-dom'
import { AppContext } from 'services/connection/models/AppContext'
import {
  ICostRange,
  IResidentCost,
} from 'contracts/costCoverages/interfaces/IResidentCost'
import { getCostRangeColor } from 'contracts/costCoverages/helpers/getCostRangeColor'
import { Callout } from 'components/Callout'
import { Dayjs } from 'dayjs'
import { CostCoverageInvoiceDialog } from './CostCoverageInvoiceDialog'
import { ICostCoverageMeta } from 'contracts/costCoverages/interfaces/ICostCoverageMeta'
import { Model } from 'components/Form/Model'
import { IResidentCostsFilterValidator } from 'contracts/costCoverages/interfaces/IResidentCostsFilterValidator'
import { InputSelectOption } from 'components/Form/components/InputSelect'
import { box } from 'services/box'
import { CostCoverageInfo } from './CostCoverageInfo'
import {
  ABRECHNUNGSMODUS_LFGB,
  ABRECHNUNGSMODUS_WOHNUNGSLOSE,
} from 'contracts/costCoverages/interfaces/abrechnungsmodus'

interface Props {
  compoundId: string
  month: string
  scope:
    | 'all'
    | 'not billed'
    | 'billable'
    | 'billed'
    | 'invalid'
    | 'changes'
    | 'missing resident signature'
  model: Model<
    IResidentCostsFilterValidator & {
      clear: number
      payerId: string | null
      payers: InputSelectOption[]
      selected: Map<string, ICostCoverageMeta | null>
      coverageIds: string[]
    }
  >
  buildingGroupId: string | null
}

@observer
export class CostCoverageListByCoverage extends React.Component<Props, {}> {
  static contextType = AppContext
  // This is an overwrite of the data in residents
  @observable dayOfCheckoutIsBillable = new Map<string, boolean>() // `${date}-${residentId}` => boolean billable?
  @observable private coverages: ICostCoverageMeta[] | null = null
  @observable private error = false
  private daysInMonth: number
  private monthBegin: Dayjs

  constructor(props: Props) {
    super(props)
    this.monthBegin = dayjs(props.month, 'YYYY-MM').startOf('month')
    this.daysInMonth = this.monthBegin.daysInMonth()
    makeObservable(this)
  }

  componentDidMount() {
    void this.getCoverages()
  }

  private getCoverages = async () => {
    try {
      runInAction(() => {
        this.props.model.values.selected = new Map()
        this.props.model.values.payers = []
        this.props.model.values.coverageIds = []
        this.props.model.values.payerId = null
      })
      const coverages = await hermes.indexOnceNew<ICostCoverageMeta>(
        `/api/${this.context.instance.id}/costCoverages/residentCosts?compoundId=${
          this.props.compoundId
        }&month=${this.props.month}&view=${encodeURIComponent(
          'cost coverages',
        )}&costCoverageScope=${encodeURIComponent(this.props.scope)}${
          this.props.buildingGroupId
            ? `&buildingGroupId=${this.props.buildingGroupId}`
            : ''
        }`,
      )
      const payers = new Map<string, string>()
      for (const coverage of coverages) {
        payers.set(coverage.payer.id, coverage.payer.label)
      }
      runInAction(() => {
        this.coverages = coverages
        const options: InputSelectOption[] = [{ value: null, label: 'Alle' }]
        for (const [id, label] of payers) {
          options.push({ value: id, label })
        }
        this.props.model.values.payers = options
        this.props.model.values.coverageIds = coverages.map((c) => c.id)
      })
    } catch (_e) {
      runInAction(() => (this.error = true))
    }
  }

  private toggleLastDayBillable = async (
    event,
    residentId: string,
    range: ICostRange,
    c: ICostCoverageMeta,
  ) => {
    event.preventDefault()
    event.stopPropagation()

    if (c.status === 'billed') {
      window.alert(
        'Die Abrechnung des Auszugstages kann nicht mehr geändert werden, da die Rechnung bereits gestellt wurde. Wenn Sie die Änderung trotzdem durchführen möchten, müssen Sie die Rechnung zunächst stornieren. Danach kann der Auszugstag wieder geändert werden.',
      )
      return
    }

    const isBillable = this.dayOfCheckoutIsBillable.has(`${range.endDate}-${residentId}`)
      ? !!this.dayOfCheckoutIsBillable.get(`${range.endDate}-${residentId}`)
      : range.internal?.dayOfCheckout === 'billable'

    if (
      !window.confirm(
        `Möchten Sie wirklich festlegen, dass der Auszugstag ${
          isBillable ? 'nicht abgerechnet werden soll?' : 'abgerechnet werden soll?'
        }`,
      )
    ) {
      return
    }
    try {
      runInAction(() =>
        this.dayOfCheckoutIsBillable.set(`${range.endDate}-${residentId}`, !isBillable),
      )
      await hermes.update(
        `/api/${this.context.instance.id}/costCoverages/bookingLastDayBillable/${this.props.compoundId}`,
        {
          days: [
            {
              date: range.endDate,
              residentId,
              billable: !isBillable,
            },
          ],
        },
      )
    } catch (e) {
      runInAction(() =>
        this.dayOfCheckoutIsBillable.set(`${range.endDate}-${residentId}`, isBillable),
      )
      console.error(e)
    }
  }

  @action
  private toggleChecked = (c: ICostCoverageMeta) => {
    this.props.model.values.selected.set(
      c.id,
      this.props.model.values.selected.get(c.id) ? null : c,
    )
  }

  @action
  private selectAll = () => {
    for (const coverage of this.coverages || []) {
      this.props.model.values.selected.set(coverage.id, coverage)
    }
  }

  @action
  private unselectAll = () => {
    for (const coverage of this.coverages || []) {
      this.props.model.values.selected.set(coverage.id, null)
    }
  }

  private coverageMapper = (c: ICostCoverageMeta) => {
    if (
      this.props.model.values.payerId &&
      c.payer.id !== this.props.model.values.payerId
    ) {
      return null
    }
    const residents = c.invoice?.residents || c.residents
    return (
      <div key={c.id} className='relative mx-6 rounded-md overflow-hidden shadow-md'>
        <div className='text-white truncate p-2 pr-4 relative bg-gray-800'>
          <input
            className='relative h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded'
            type='checkbox'
            style={{ top: -1 }}
            checked={!!this.props.model.values.selected.get(c.id)}
            onChange={() => this.toggleChecked(c)}
          />
          {c.status === 'not billed' && (
            <span className='inline-flex items-center mx-2 px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-500 text-white'>
              Entwurf
            </span>
          )}
          {c.status === 'billed' && (
            <span className='inline-flex items-center mx-2 px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-500 text-white'>
              Abgerechnet
            </span>
          )}
          {!c.status && <span>&nbsp;&nbsp;</span>}
          {!!c.invoice?.changes && (
            <span
              onClick={(e) => {
                e.stopPropagation()
                this.showChanges(c.invoice?.changes!)
              }}
              className='cursor-pointer inline-flex items-center mr-2 px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-500 text-white'
            >
              Geändert
            </span>
          )}
          <span className='text-white'>
            {`${dayjs(c.beginDate).format('DD.MM.YY')} - ${dayjs(c.endDate).format(
              'DD.MM.YY',
            )}`}
            &nbsp;&nbsp;·&nbsp;&nbsp;{c.payer.label}&nbsp;&nbsp;·&nbsp;&nbsp;
            {c.aktenzeichen}
          </span>
        </div>
        <CostCoverageInfo coverage={c} />
        {residents.length > 0 && (
          <div className='bg-white flex-content grid grid-cols-[250px_1fr] text-sm'>
            {residents.map((r) => this.residentMapper(r, c))}
          </div>
        )}
        <div className='bg-white mr-6' style={{ width: 8 }} />
      </div>
    )
  }

  private showChanges = (changes: string[]) => {
    void box.alert(
      'Änderungen',
      'Seit der Abrechnung wurden neue Daten eingetragen, die ggf. eine Stornierung der Rechnung rechtfertigen. Die Rechnung kann danach mit dem geänderten Sachverhalt neu gestellt werden: ' +
        changes.join(', '),
    )
  }

  private residentMapper = (r: IResidentCost, c: ICostCoverageMeta) => {
    return (
      <React.Fragment key={r.id}>
        <div className='bg-white truncate p-2 pr-4'>
          <Link
            className='underline hover:text-blue-500'
            to={`/residents/${toJbpId(+r.id).toLowerCase()}/accommodation`}
            target='_blank'
          >{`${r.lastName.toUpperCase()}, ${r.firstName}`}</Link>{' '}
          <Age dateOfBirth={r.dateOfBirth} sex={r.sex} />
        </div>
        <div className='bg-white py-2 relative mr-2'>
          {r.sections.map((b, key) => {
            const beginDay = -this.monthBegin.diff(b.beginDate, 'day')
            const endDay = -this.monthBegin.diff(b.endDate, 'day')
            const color = getCostRangeColor(b)
            const offerInvoice =
              color.endsWith('bg-green-500') &&
              this.context.permissions.resident_abrechnung_invoice &&
              this.context.permissions.abrechnungsmodus !==
                ABRECHNUNGSMODUS_WOHNUNGSLOSE &&
              !!c.status /* !c.status => invoice can only be created for building group, but no building group selected */
            const lastDayIsBillable = this.dayOfCheckoutIsBillable.has(
              `${b.endDate}-${r.id}`,
            )
              ? !!this.dayOfCheckoutIsBillable.get(`${b.endDate}-${r.id}`)
              : b.internal?.dayOfCheckout === 'billable'
            return (
              <div
                key={key}
                className='absolute truncate text-white'
                style={{
                  padding: '0 1px',
                  left: `${(beginDay / this.daysInMonth) * 100}%`,
                  width: `${((endDay - beginDay + 1) / this.daysInMonth) * 100}%`,
                  height: 21,
                }}
              >
                {!offerInvoice && (
                  <div
                    className={`${color} ${
                      offerInvoice ? 'cursor-pointer' : ''
                    } rounded-sm px-1`}
                  >
                    {b.external?.reason === 'Krankenhausaufenthalt' && (
                      <span className='mr-1'>
                        <i className='fas fa-ambulance' />
                      </span>
                    )}
                    {(b.external?.reason === 'Genehmigte Ortsabwesenheit' ||
                      b.external?.reason === '') && (
                      <span className='mr-1'>
                        <i className='fas fa-umbrella-beach' />
                      </span>
                    )}
                    {b.internal ? (
                      getDateLabel(
                        dayjs(b.beginDate),
                        dayjs(b.endDate),
                        b.internal.hasCheckOut,
                      )
                    ) : (
                      <>&nbsp;</>
                    )}
                    {b.internal?.hasCheckOut && (
                      <div className='absolute top-1 right-1 h-3 w-3 bg-white rounded-sm' />
                    )}
                  </div>
                )}
                {offerInvoice && (
                  <Link
                    to={
                      c.invoice?.id ||
                      `${b.coverage?.id || ''}-${
                        this.props.model.values.buildingGroupId || '0'
                      }-${this.props.month}`
                    }
                  >
                    <div className={`${color} rounded-sm px-1`}>
                      {b.external?.reason === 'Krankenhausaufenthalt' && (
                        <span className='mr-1'>
                          <i className='fas fa-ambulance' />
                        </span>
                      )}
                      {(b.external?.reason === 'Genehmigte Ortsabwesenheit' ||
                        b.external?.reason === '') && (
                        <span className='mr-1'>
                          <i className='fas fa-umbrella-beach' />
                        </span>
                      )}
                      {b.internal ? (
                        getDateLabel(
                          dayjs(b.beginDate),
                          dayjs(b.endDate),
                          b.internal.hasCheckOut,
                        )
                      ) : (
                        <>&nbsp;</>
                      )}
                      {b.internal?.hasCheckOut && (
                        <div
                          className='absolute top-1 right-1 h-3 w-3 bg-white rounded-sm text-green-500 text-center'
                          style={{ lineHeight: '13px' }}
                          onClick={(event) =>
                            this.toggleLastDayBillable(event, r.id, b, c)
                          }
                        >
                          {lastDayIsBillable ? '$' : ''}
                        </div>
                      )}
                    </div>
                  </Link>
                )}
              </div>
            )
          })}
          {r.sections.length === 0 && (
            <div className='absolute top-0 left-0 bottom-0 bg-white truncate p-2 pr-4 text-sm text-gray-500'>
              Bewohner hat keine Belegung in dieser Unterkunft
            </div>
          )}
        </div>
      </React.Fragment>
    )
  }

  render() {
    if (!this.coverages) {
      return (
        <div className='flex-auto bg-gray-100 flex'>
          <Spinner delay />
        </div>
      )
    }
    if (this.error) {
      return (
        <div className='flex-auto bg-gray-100 flex'>
          <Callout
            icon='fas fa-exclamation-triangle'
            iconColor='#ef4444'
            title='Die Liste konnte nicht geladen werden.'
          />
        </div>
      )
    }
    if (this.coverages.length === 0) {
      return (
        <div className='flex-auto bg-gray-100 flex'>
          <Callout
            icon='fas fa-list-ul'
            iconColor='#8b5cf6'
            title='Für den gewählten Filter liegen keine KÜs vor.'
          />
        </div>
      )
    }

    return (
      <>
        <div className='relative md:sticky md:z-1 md:top-[197px] xl:top-[143px] flex-content flex px-6 py-3 bg-gray-100 gap-3 text-sm flex-wrap'>
          <div
            className='whitespace-nowrap cursor-pointer bg-gray-500 rounded-md text-white py-1 px-2'
            onClick={this.selectAll}
          >
            <i className='fas fa-stop' /> Alle auswählen
          </div>
          <div
            className='whitespace-nowrap cursor-pointer bg-gray-500 rounded-md text-white py-1 px-2'
            onClick={this.unselectAll}
          >
            <i className='fas fa-stop' /> Alle abwählen
          </div>
          {this.context.permissions.abrechnungsmodus === ABRECHNUNGSMODUS_LFGB && (
            <div className='whitespace-nowrap bg-indigo-500 rounded-md text-white py-1 px-2'>
              <i className='fas fa-ambulance' /> Krankenhaus
            </div>
          )}
          {this.context.permissions.abrechnungsmodus === ABRECHNUNGSMODUS_LFGB && (
            <div className='whitespace-nowrap bg-indigo-500 rounded-md text-white py-1 px-2'>
              <i className='fas fa-umbrella-beach' /> Abwesenheit
            </div>
          )}
          <div className='whitespace-nowrap bg-green-500 rounded-md text-white py-1 px-2'>
            Abrechenbar
          </div>
          {this.context.permissions.abrechnungsmodus === ABRECHNUNGSMODUS_LFGB && (
            <div className='whitespace-nowrap bg-red-500 rounded-md text-white py-1 px-2'>
              KÜ liegt vor, aber UKN/LB fehlt
            </div>
          )}
          {this.context.permissions.abrechnungsmodus === ABRECHNUNGSMODUS_LFGB && (
            <div className='whitespace-nowrap bg-gray-500 rounded-md text-white py-1 px-2'>
              KÜ ungültig
            </div>
          )}
        </div>
        <div className='flex-content bg-gray-100 flex flex-col gap-4 pb-6 pt-1 text-sm'>
          {this.coverages.map(this.coverageMapper)}
          <CostCoverageInvoiceDialog />
        </div>
      </>
    )
  }
}

function getDateLabel(beginDate: Dayjs, endDate: Dayjs, hasCheckOut?: boolean): string {
  if (beginDate.isSame(endDate, 'day')) {
    return hasCheckOut ? endDate.format('DD.') : endDate.format('DD.MM.')
  }
  return `${beginDate.format('DD.')} - ${endDate.format('DD.MM.')}`
}
