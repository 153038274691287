import { env } from '../../../env'

export interface ITodoDefinition {
  label: string
  reset?: true
}

// reset = true --> will be unchecked when new responsibility is defined.
const lfgbDefaultResidentTodos: ITodoDefinition[] = [
  { label: 'TBC Untersuchung' },
  { label: 'Masern Untersuchung' },
  { label: 'Masern-Impfung 1' },
  { label: 'Masern-Impfung 2' },
  { label: 'Polizeiliche Meldung', reset: true },
  { label: 'Erstausstattung erhalten', reset: true },
  { label: 'Ausweis erhalten', reset: true },
  { label: 'Datenschutz Unterschrift', reset: true },
  { label: 'Hausordnung Unterschrift', reset: true },
]

const appDefaultTodos: ITodoDefinition[] = [{ label: 'Gesundheitsuntersuchung' }]

export function getDefaultResidentTodos(instanceId: string): ITodoDefinition[] {
  switch (env.DEV_SIMULATE_INSTANCE || instanceId) {
    case '0':
    case '1': // app.inuv.de
    case '2': // testapp.inuv.de
      return appDefaultTodos
    case '7': // inuv.lfg-b.local
    case '8': // testinuv.lfg-b.local
      return lfgbDefaultResidentTodos
    default:
      return []
  }
}
